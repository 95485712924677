<style lang="scss" scoped>

</style>
<template>

    <div>
        <el-footer class="com-eTrip-footer" height="64px">
            <div class="eTrip-footer-cont table-page-number">
                <div class="block">
                    <el-pagination
                            @current-change="initData"
                            :current-page.sync="pageInfo.pageIndex"
                            :page-size="pageInfo.pageSize"
                            layout="total, prev, pager, next"
                            :total="pageInfo.total"
                    ></el-pagination>
                </div>
            </div>
        </el-footer>

    </div>

</template>

<script type="text/ecmascript-6">
    export default {
        //定义模版数据
        props: {
            pageInfo: {
                type: Object,
                default: function () {
                    let data = {
                        pageIndex: 1,
                        pageSize: 10,
                        total: 1,
                    };
                    return data
                }
            }
        },
        components: {},
        //计算属性
        computed: {},
        //主件被加载完成
        mounted: function () {

        },
        //定义事件方法
        methods: {
            initData(pageIndex){
                this.$emit("update:initData", pageIndex)
            }

        },
        //监听模版变量
        watch: {}

    }
</script>

