<style lang="scss" scoped>
.insurance-list-search /deep/ {
    .common-list-search {
        .list-search-before {
            width: 100%;

            .el-form-item.el-form-item--small {
                width: auto;
                margin-bottom: 10px;
            }
        }

        .list-search-after {
            display: none;
        }
    }
}

.screen-company-btn {
    /*float: right;*/
    position: relative;
    height: 40px;

    .screen-operate-sort {
        position: absolute;
        bottom: 0;
        left: 10px;
        font-size: 12px;

        .el-button {
            padding: 8px 20px;
        }

        .i-top {
            position: absolute;
            right: 5px;
            top: 5px;
        }

        .i-bottom {
            position: absolute;
            right: 5px;
            bottom: 5px;
        }
    }

    .screen-operate-btn {
        position: absolute;
        bottom: 0;
        right: 0;

        .el-button {
            padding: 8px 20px;
        }
    }
}
</style>
<template>

    <div class="insurance-list-search">
        <com-list-search>
            <div slot="list-search-before">
                <el-form :inline="true" :model="form" size="small">
                    <el-form-item>
                        <el-input
                            style="width: 120px"
                            v-model="form.orderNo"
                            placeholder="订单编号">
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-input
                            style="width: 120px"
                            v-model="form.orgPlatOrderId"
                            placeholder="源平台订单号">
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-select
                            style="width: 180px"
                            v-model="form.productStartCityId"
                            placeholder="出发城市"
                            filterable
                            clearable
                            size="small">
                            <el-option
                                v-for="(item,index) in cityList"
                                :key="index"
                                :label="item.name"
                                :value="item.shortCode">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-date-picker
                            style="width: 240px"
                            v-model="form.createOrderTime"
                            size="small"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="下单起始日期"
                            end-placeholder="下单截止日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-autocomplete
                            style="width: 120px"
                            v-model="form.tripUserName"
                            :fetch-suggestions="searchTripUserName"
                            placeholder="出行人姓名"
                            @select="selectTripUserName">
                            <template slot-scope="{ item }">
                                <div class="list-pop-bot">
                                    <!--<span>{{'[' + item.orgId + ']'}}</span>-->
                                    <span>{{ item.tripUserName }}</span>
                                </div>
                            </template>
                        </el-autocomplete>
                    </el-form-item>
                    <el-form-item>
                        <el-input
                            style="width: 120px"
                            placeholder="出行人手机号"
                            maxlength="11"
                            v-model="form.tripUserMobile"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-date-picker
                            style="width: 240px"
                            v-model="form.tripTime"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="出行起始日期"
                            end-placeholder="出行截止日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-autocomplete
                            style="width: 120px"
                            v-model="form.driverName"
                            :fetch-suggestions="searchDriverName"
                            placeholder="司导姓名"
                            @select="selectDriverName">
                            <template slot-scope="{ item }">
                                <div class="list-pop-bot">
                                    <span>{{ item.driverName }}</span>
                                </div>
                            </template>
                        </el-autocomplete>
                    </el-form-item>
                    <el-form-item>
                        <el-input
                            style="width: 120px"
                            placeholder="司导手机号"
                            maxlength="11"
                            v-model="form.driverMobile"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-autocomplete
                            style="width: 120px"
                            v-model="form.vehicleNo"
                            :fetch-suggestions="searchVehicleNo"
                            placeholder="车牌号"
                            @select="selectVehicleNo">
                            <template slot-scope="{ item }">
                                <div class="list-pop-bot">
                                    <span>{{ item.carNumberPlate }}</span>
                                </div>
                            </template>
                        </el-autocomplete>
                    </el-form-item>
                    <el-form-item>
                        <el-autocomplete
                            style="width: 170px"
                            v-model="form.productName"
                            :fetch-suggestions="searchProductName"
                            placeholder="产品名称"
                            @select="selectProductName">
                            <template slot-scope="{ item }">
                                <div class="list-pop-bot">
                                    <span>{{ item.productName }}</span>
                                </div>
                            </template>
                        </el-autocomplete>
                    </el-form-item>
                    <el-form-item>
                        <el-date-picker
                            style="width: 240px"
                            v-model="form.insuranceTime"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="投保起始日期"
                            end-placeholder="投保截止日期">
                        </el-date-picker>
                    </el-form-item>
                </el-form>
                <el-row :gutter="20" class="screen-company-btn">
                    <el-col :span="12">
                        <div class="screen-operate-sort">
                            <div style="display: inline-block">
                                <span>排序:</span>
                                <el-button type="text" style="color: #333;position: relative" @click="handleSort(1)">
                                    下单时间
                                    <i class="el-icon-caret-top i-top"
                                       :style="{color: orderSort==2?'#319BF7':'#999'}"></i>
                                    <i class="el-icon-caret-bottom i-bottom"
                                       :style="{color: orderSort==1?'#319BF7':'#999'}"></i>
                                </el-button>
                                <el-button type="text" style="color: #333;position: relative" @click="handleTime(1)">
                                    出行时间
                                    <i class="el-icon-caret-top i-top"
                                       :style="{color: orderSort==4?'#319BF7':'#999'}"></i>
                                    <i class="el-icon-caret-bottom i-bottom"
                                       :style="{color: orderSort==3?'#319BF7':'#999'}"></i>
                                </el-button>
                            </div>

                        </div>

                    </el-col>
                    <el-col :span="12">
                        <div class="screen-operate-btn">
                            <el-button type="primary" @click="exportBtn()">导出</el-button>
                            <el-button @click="resetForm()">重置</el-button>
                            <el-button type="primary" @click="search(1)">搜索</el-button>

                        </div>
                    </el-col>
                </el-row>
            </div>
        </com-list-search>
    </div>

</template>

<script type="text/ecmascript-6">
import comListSearch from '@/www/components/comListSearch/index.vue'
import {initForm} from '@/www/vuex/modules/insuranceList'
import {getCityData} from '@/urls/index';
import {
    selectTripUserInfoByName,
    doGalaxySelectDriverInfoByName,
    doGalaxyGetCarInfoByNumberPlate,
    selectProductByName
} from '@/www/urls/insuranceListUrls';
import dayjs from "dayjs";

export default {
    //定义模版数据
    data() {
        return {


            form: {
                ...initForm
            },

            orderSort: true,
            cityList: []

        }
    },
    props: {},
    components: {
        comListSearch
    },
    //计算属性
    computed: {},
    //主件被加载完成
    mounted: function () {
        this.getServerCity();
    },
    //定义事件方法
    methods: {
        async getServerCity() {
            const ret = await getCityData(this, '2');
            if (ret)
                this.cityList = ret;
        },
        search(pageIndex) {

            let form = JSON.parse(JSON.stringify(this.form));
            if (form.createOrderTime) {
                form.createOrderStartTime = +new Date(form.createOrderTime[0]);
                form.createOrderEndTime = dayjs(form.createOrderTime[1]).endOf('d').valueOf();
                delete form.createOrderTime;
            }
            if (form.tripTime) {
                form.tripStartTime = +new Date(form.tripTime[0]);
                form.tripEndTime = +new Date(form.tripTime[1]);
                delete form.tripTime;
            }
            if (form.insuranceTime) {
                form.insuranceStartTime = +new Date(form.insuranceTime[0]);
                form.insuranceEndTime = +new Date(form.insuranceTime[1]);
            }

            form.sortField = 'create_time';
            form.sortOrder = 'DESC';
            this.$store.dispatch('search_insurance_list', {pageIndex, form})
        },
        exportBtn() {
            let form = JSON.parse(JSON.stringify(this.form));
            if ((!form.createOrderTime || !form.createOrderTime.length) && (!form.tripTime || !form.tripTime.length) && (!form.insuranceTime || !form.insuranceTime.length))
                return this.$alert('请选择日期', '提示', {
                    type: 'error'
                })
            if ((form.createOrderTime && dayjs(form.createOrderTime[1]).diff(form.createOrderTime[0], 'd') > 40) ||
                (form.tripTime && dayjs(form.tripTime[1]).diff(form.createOrderTime[0], 'd') > 40) ||
                (form.insuranceTime && dayjs(form.insuranceTime[1]).diff(form.createOrderTime[0], 'd') > 40))
                return this.$alert('起始日期距离截止日期超过40天，无法导出！', '提示', {
                    type: 'error'
                })

            if (form.createOrderTime) {
                form.createOrderStartTime = +new Date(form.createOrderTime[0]);
                form.createOrderEndTime = dayjs(form.createOrderTime[1]).endOf('d').valueOf();
                delete form.createOrderTime;
            }

            if (form.tripTime) {
                form.tripStartTime = +new Date(form.tripTime[0]);
                form.tripEndTime = +new Date(form.tripTime[1]);
                delete form.tripTime;
            }
            if (form.insuranceTime) {
                form.insuranceStartTime = +new Date(form.insuranceTime[0]);
                form.insuranceEndTime = +new Date(form.insuranceTime[1]);
            }

            form.sortField = 'create_time';
            form.sortOrder = 'DESC';
            this.$store.dispatch('export_insurance_order', {form})
        },
        resetForm() {
            this.form = {...initForm}
        },

        searchTripUserName(query, cb) {
            if (query && query.length > 0) {
                this.querySearch(query, cb, 3)
            }
        },

        searchDriverName(query, cb) {
            if (query && query.length > 0) {
                this.querySearch(query, cb, 4)
            }
        },

        searchVehicleNo(query, cb) {
            if (query && query.length > 0) {
                this.querySearch(query, cb, 5)
            }
        },//searchVehicleNo
        searchProductName(query, cb) {
            if (query && query.length > 0) {
                this.querySearch(query, cb, 6)
            }
        },
        async querySearch(query, cb, index) {
            let res = '';
            let form = {};
            switch (index) {
                case 3:
                    res = await selectTripUserInfoByName(query);
                    break;
                case 4:
                    form = {
                        driverName: query
                    };
                    res = await doGalaxySelectDriverInfoByName(form);
                    break;
                case 5:
                    res = await doGalaxyGetCarInfoByNumberPlate(query);
                    break;
                case 6:
                    form = {
                        productName: query,
                        productTypes: this.type === 'trip' ? ['3'] : ['1', '2']
                    };
                    res = await selectProductByName(form);
                    break;
            }
            if (res.success && res.data) {
                // let mechanismList = res.data;
                let mechanismList = query ? res.data.filter(this.createFilter(query, index)) : res.data;
                cb(mechanismList);
            } else {
                this.messageError('系统出错，请稍后!');
            }
        },

        createFilter(itemStr, index) {
            return (restaurant) => {
                let result = false;
                switch (index) {
                    case 3:
                        result = (restaurant.tripUserName.indexOf(itemStr) >= 0);
                        break;
                    case 4:
                        result = (restaurant.driverName.indexOf(itemStr) >= 0);
                        break;
                    case 5:
                        result = (restaurant.carNumberPlate.indexOf(itemStr) >= 0);
                        break;
                    case 6:
                        result = (restaurant.productName.indexOf(itemStr) >= 0);
                        break;
                }
                return result;
            };
        },

        selectTripUserName(val) {
            this.form.tripUserName = val.tripUserName;
        },

        selectDriverName(val) {
            this.form.driverName = val.driverName;
        },

        selectVehicleNo(val) {
            this.form.vehicleNo = val.carNumberPlate;
        },
        selectProductName(val) {
            this.form.productName = val.productName;
        },


        handleSort(pageIndex) {
            let form = JSON.parse(JSON.stringify(this.form));
            if (this.orderSort != 1) this.orderSort = 1;
            else this.orderSort = 2;
            form.sortField = 'create_time';
            form.sortOrder = this.orderSort === 1 ? 'DESC' : 'ASC';
            this.$store.dispatch('search_insurance_list', {pageIndex, form})
        },
        handleTime(pageIndex) {
            let form = JSON.parse(JSON.stringify(this.form));
            if (this.orderSort != 3) this.orderSort = 3;
            else this.orderSort = 4;
            form.sortField = 'reserve_time';
            form.sortOrder = this.orderSort === 3 ? 'DESC' : 'ASC';
            this.$store.dispatch('search_insurance_list', {pageIndex, form})
        }
    },
    //监听模版变量
    watch: {}

}
</script>

