<style lang="scss" scoped>

</style>
<template>

    <div>
        <div v-for="(item, index) in list" :key="item.id">
            <order-main :order-info="item" :user-info="userInfo" :order-info-index="index" order-type="insuranceList"></order-main>
        </div>
    </div>

</template>

<script type="text/ecmascript-6">
    import orderMain from '../../components/orderMain/'
    export default {
        //定义模版数据
        data() {
            return {}
        },
        props: {},
        components: {
            orderMain
        },
        //计算属性
        computed: {
            userInfo() {
                return this.$store.state.loginUserInfo;
            },
            list() {
                return this.$store.state.insuranceList.list
            },
        },
        //主件被加载完成
        mounted: function () {

        },
        //定义事件方法
        methods: {},
        //监听模版变量
        watch: {}

    }
</script>

