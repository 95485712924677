<style lang="scss" scoped>

</style>
<template>

    <div>
        <el-main class="com-eTrip-section section-has-footer">
            <div class="eTrip-section-cont">
                <insurance-search ref="insuranceSearchRef"></insurance-search>

                <insurance-table style="margin-top: 10px"></insurance-table>
            </div>
        </el-main>
        <footer-pagination
                :pageInfo="pageInfo"
                @update:initData="search">
        </footer-pagination>
    </div>

</template>

<script type="text/ecmascript-6">
    import insuranceSearch from "./search/index"
    import insuranceTable from "./table/index"

    import footerPagination from "@/www/components/footerPagination/index"

    export default {
        name: 'insuranceList',
        //定义模版数据
        data() {
            return {
                // refresh: null
            }
        },
        components: {
            insuranceSearch,
            insuranceTable,
            footerPagination
        }
        ,
        //计算属性
        computed: {
            pageInfo() {
                return this.$store.state.insuranceList.page
            }

        }
        ,
        //主件被加载完成
        mounted: function () {
            this.search(1);
        },
        activated(){
            // if (this.refresh !== this.$route.query.refresh) {
            //     this.search(1);
            //     this.refresh = this.$route.query.refresh
            // }
        },
        //定义事件方法
        methods: {
            search(index) {
                this.$refs['insuranceSearchRef'].search(index)
            }

        }
        ,
        //监听模版变量
        watch: {}

    }
</script>

